<template>
  <div class="home">
    <img src="~img/26.svg" class="logo">
    <header>
      <ul>
        <li>
          <p @click='$router.push({ path: "/desksidea" })' @mouseover='mouseOver' @mouseleave='mouseLeave'>
            SHOP<br>ALL</p>
          <!-- <img src="~img/22.png"> -->
          <div @mouseover='mouseOver' @mouseleave='mouseLeave' @click='$router.push({ path: "/desksidea" })'>
            <img src="~img/30.png" class="cloes" v-if="showCloes">
            <img src="~img/29.png" class="open" v-if="showOpen">
          </div>
        </li>
        <!--  -->
        <li @mouseover='mouseOvertow' @mouseleave='mouseLeavetow'>
          <p @click='$router.push({ path: "/side" })'>BE OUR<br />PUZZ-BUD</p>
          <!-- <img src="~img/23.png" class="tow-img"> -->
          <img @click='$router.push({ path: "/side" })' v-if="showFk" src="~img/27.png" class="Writing-board">
          <img @click='$router.push({ path: "/side" })' v-if="showWs" src="~img/55.png"
            class="Writing-board Writing-board-tow">
        </li>
        <li>
          <p @click='$router.push({ path: "/project" })' @mouseover='mouseOverthree' @mouseleave='mouseLeavethree'>
            OUR<br />STORIES</p>
          <!-- <img src="~img/24.png" class="three-img"> -->
          <!-- <img @click='$router.push({ path: "/deskabout" })' src="~img/questionmark.gif" class="ask"> -->
          <div @mouseover='mouseOverthree' @mouseleave='mouseLeavethree'>
            <img src="~img/57.png" v-if="showkf" class="kafei">
            <img @click='$router.push({ path: "/project" })' src="~img/56.png" v-if="showdfk" class="kafei dkafei">
          </div>
        </li>
        <li>
          <p @click="$router.push({path : '/deskabout'})">FIND<br>US</p>
          <!-- <img src="~img/25.png"> -->
          <img @click="$router.push({path : '/deskabout'})" src="~img/questionmark.gif" class="bomb">
        </li>
      </ul>
    </header>
    <!-- <div class="count-down">
      <span><span v-show="hours < 10">0</span>{{hours}}</span>
      <span>:</span>
      <span><span v-show="minutes < 10">0</span>{{minutes}}</span>
      <span>:</span>
      <span><span v-show="seconds < 10">0</span>{{seconds}}</span>
    </div> -->
    <audio ref="audio" :src="musicurl" v-if="musicurl" autoplay controls="controls" loop="loop"></audio>
    <img src="~img/37.png" class="music-icon" @click="play" v-show="showPlay && musicurl">
    <img src="~img/36.png" class="music-icon" @click="pause" v-show="!showPlay && musicurl">
    <horserace :color='noticeColor' :background='background' />
    <a href="https://beian.miit.gov.cn/#/Integrated/index">
      <p class="biean">京 IPC 备 2021037798 号 -1</p>
    </a>
  </div>
</template>



<script>
import panav from "@/components/pc/nav.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    panav,
    horserace,
  },
  data() {
    return {
      color: "#4aaeff",
      showOpen: false,
      showCloes: true,
      noticeColor: "#fff",
      background: "#ff7030",
      t: null,
      completeTime: 0, // 目标时间
      time: "",
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      musicurl: "",
      showPlay: true,
      showWs: false,
      showFk: true,
      showkf: true,
      showdfk: false,
    };
  },
  created() {
    this.getTiem();
  },
  mounted() {},
  methods: {
    play() {
      this.showPlay = false;
      this.$refs.audio.pause();
    },
    pause() {
      this.showPlay = true;
      this.$refs.audio.play();
    },
    async getTiem() {
      const res = await this.axios.get("/api/index/time");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        console.log(data);
        this.musicurl = data.music;
        this.completeTime = data.times;
        console.log(this.$refs.audio);
        let that = this;
        if (this.musicurl) {
          setTimeout(() => {
            that.$refs.audio.play();
          }, 500);
        }
        this.interval(this.completeTime);
      }
    },
    mouseOver() {
      this.showOpen = true;
      this.showCloes = false;
    },
    mouseLeave() {
      this.showOpen = false;
      this.showCloes = true;
    },
    mouseOvertow() {
      this.showWs = true;
      this.showFk = false;
    },
    mouseLeavetow() {
      this.showWs = false;
      this.showFk = true;
    },
    mouseOverthree() {
      this.showkf = false;
      this.showdfk = true;
    },
    mouseLeavethree() {
      this.showkf = true;
      this.showdfk = false;
    },
    interval(completeTime) {
      var stime = Date.parse(new Date()); // 当前时间
      var etime = Date.parse(new Date(completeTime));
      var usedTime = etime - stime; //两个时间戳相差的毫秒数
      // console.log(usedTime);
      if (usedTime < 0) return;
      this.days = Math.floor(usedTime / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = usedTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      this.hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      this.minutes = Math.floor(leave2 / (60 * 1000));
      let leave3 = leave2 % (60 * 1000);
      this.seconds = Math.round(leave3 / 1000);
      this.time = setTimeout(() => {
        this.interval(this.completeTime);
      }, 1000);
    },
  },
  destroyed() {
    clearTimeout(this.time); // 清除
  },
};
</script>


<style lang="less" scoped>
.home {
  height: 100vh;
  max-height: 100vh;
  background: #ffd7d6;
  position: relative;
  overflow: hidden;
  .logo {
    width: 4rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  header {
    ul {
      display: flex;
      li {
        width: 25%;
        // padding-top: 0.15rem /* 22/100 */;
        position: relative;
        cursor: pointer;
        height: 100vh;
        p {
          text-align: center;
          font-size: 0.8rem;
          font-family: PHANBO;
          color: coral;
          -webkit-text-stroke: 0.03rem #000;
          z-index: 999;
          position: relative;
          line-height: 0.8rem;
        }
        P:hover {
          color: #fff;
        }
        img {
          width: 60%;
          margin: 0 auto;
          display: block;
        }
        .three-img {
          width: 50%;
        }
        .Writing-board {
          // width: 4.03rem /* 403/100 */;
          width: 100%;
          position: absolute;
          left: 50%;
          top: -170px;
          transform: translate(-50%);
          transition: 0.5s all;
        }
        Writing-board-tow {
          top: 0;
        }
        // .Writing-board:hover {
        //   top: 5%;
        //   z-index: 99;
        // }
        .tow-img {
          position: relative;
          z-index: 9;
        }
        .ask {
          width: 1.56rem /* 156/100 */;
          position: absolute;
          left: -70%;
          bottom: 0.7rem;
          transition: 0.5s all;
        }
        .kafei {
          position: absolute;
          bottom: 1rem;
          right: -1rem;
          width: 5.21rem;
        }
        .dkafei {
          width: 5.21rem;
        }
        .ask:hover {
          width: 4rem;
          left: -50%;
        }
        .bomb {
          position: absolute;
          // right: -2.5rem /* 10/100 */;
          top: 3rem /* 90/100 */;
          width: 2rem /* 515/100 */;
          transition: 1s all;
        }
        .bomb:hover {
          top: 2rem;
          right: 3rem;
          transform: scale(1.5);
        }
      }
      li:nth-child(1) {
        img {
          width: 3.7rem;
          position: absolute;
          bottom: 0.7rem /* 91/100 */;
          left: 0.2rem /* 19/100 */;
        }
        .open {
          width: 3.497rem;
          left: 0.4rem;
        }
      }
      li:nth-child(2) {
        height: 200px;
        p:hover + .Writing-board {
          top: 130px;
        }
        p {
          color: #9f9279;
        }
        P:hover {
          color: #fff;
        }
      }
      li:nth-child(3) {
        // background-color: #fff;
        // p:hover + .ask {
        //   width: 4rem;
        //   left: -50%;
        // }
        p {
          color: #b7d3f3;
        }
        P:hover {
          color: #fff;
        }
      }
      li:nth-child(4) {
        p:hover + .bomb {
          top: 2rem;
          right: 3rem;
          transform: scale(1.5);
        }

        p {
          color: #419065;
        }
        P:hover {
          color: #fff;
        }
      }
    }
  }
  .box {
    position: absolute;
    bottom: 0.91rem /* 91/100 */;
    left: 0.2rem /* 19/100 */;
    .cloes {
      width: 3.7rem /* 455/100 */;
    }
  }
  .bomb {
    position: absolute;
    // right: -2.5rem /* 10/100 */;
    // right: 1rem;
    right: 0.19rem;
    bottom: 1.5rem /* 90/100 */;
    transition: 1s all;
    img {
      width: 7.5rem /* 515/100 */;
    }
  }
  // .bomb:hover {
  //   right: -2.5rem;
  // }
  .count-down {
    width: 3rem /* 300/100 */;
    height: 0.65rem /* 65/100 */;
    background: rgb(199, 197, 197);
    position: absolute;
    right: 0.22rem /* 22/100 */;
    bottom: 0.7rem /* 100/100 */;
    border-radius: 0.15rem /* 15/100 */;
    font-size: 0.5rem /* 40/100 */;
    line-height: 0.7rem /* 65/100 */;
    text-align: center;
    font-family: DIGItAL;
    color: #000;
    // .span2 {
    //   line-height: 0.5rem /* 50/100 */;
    //   display: inline-block;
    // }
  }
  .music {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
  }
  audio {
    position: absolute;
    right: 4rem /* 22/100 */;
    bottom: 0.7rem /* 100/100 */;
    opacity: 0;
  }
  .music-icon {
    width: 0.7rem;
    z-index: 9999;
    position: absolute;
    bottom: 0.4rem;
    left: 2%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .biean {
    position: absolute;
    bottom: 0.7rem;
    left: 50%;
    transform: translate(-50%);
    font-size: 0.135rem;
    color: #ff6537;
  }
}
</style>
